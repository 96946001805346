<template>
  <section class="container-fluid __modelo8x8-container mt-3">
    <div class="row mt-3">
      <div class="w-100 d-flex justify-content-start ms-8">
        <h2>
          <span style="color: #14993c"> Español </span>
        </h2>
      </div>
      <div class="w-100 d-flex justify-content-center mb-2">
        <input type="text" class="form-control subtitle" v-model="titleES" />
      </div>
      <div class="w-100 d-flex justify-content-center">
        <input
          type="text"
          class="form-control form-control-sm subtitle"
          v-model="subtitle_1ES"
        />
      </div>
      <div class="w-100">
        <div class="mx-md-auto my-4">
          <div class="row justify-content-center fw-bold">
            <div class="col-12 col-sm-3 my-2 m-sm-0">
              <input
                type="text"
                class="form-control boton-verde w-100"
                v-model="green_buttonES"
              />
            </div>
            <div class="col-12 col-sm-3 my-2 m-sm-0">
              <input
                type="text"
                class="form-control boton-celeste w-100"
                v-model="blue_buttonES"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="w-100 d-flex justify-content-center">
        <input
          type="text"
          class="form-control form-control-sm mb-5 subtitle"
          v-model="subtitle_2ES"
        />
      </div>
    </div>
    <div class="container">
      <div class="row m-0 section-table">
        <div
          class="col-12 col-lg-6 p-0"
          v-for="column in tableES"
          :key="column.id"
        >
          <h3
            id="tablaEstrategiaTitulo"
            class="text-center wet-form pb-1 m-0"
            :class="{
              'container-titulo-estrategia': column.color === 'green',
              'container-titulo-ejecucion': column.color === 'blue',
            }"
          >
            <input class="form-control" v-model="column.title" />
          </h3>
          <div class="row container-informacion-tabla w-100 m-0">
            <div
              class="col-3 form wet-form"
              :class="{
                'th-verde': column.color === 'green',
                'th-celeste': column.color === 'blue',
              }"
              v-for="cellTitle in column.th"
              :key="cellTitle.id"
            >
              <input
                class="mb-0 fw-bold form-control"
                v-model="cellTitle.text"
              />
            </div>
            <div
              class="col-3 form wet-form"
              :class="{
                'tr-verde': column.color === 'green',
                'tr-celeste': column.color === 'blue',
              }"
              v-for="cell in column.tr"
              :key="cell.id"
            >
              <textarea
                class="my-1 form-control"
                v-model="cell.text"
                rows="3"
              />
            </div>
          </div>
        </div>
      </div>
      <button
        class="btn btn-success btn-lg mt-3 mb-3"
        @click="syncContent('ES')"
      >
        Guardar
      </button>
    </div>
    <div class="w-100">
      <hr class="pill my-5" />
    </div>
    <div class="row mt-3">
        <div class="w-100 d-flex justify-content-start ms-8">
        <h2>
          <span style="color: #14993c"> English </span>
        </h2>
      </div>
      <div class="w-100 d-flex justify-content-center mb-2">
        <input type="text" class="form-control subtitle" v-model="titleEN" />
      </div>
      <div class="w-100 d-flex justify-content-center">
        <input
          type="text"
          class="form-control form-control-sm subtitle"
          v-model="subtitle_1EN"
        />
      </div>
      <div class="w-100">
        <div class="mx-md-auto my-4">
          <div class="row justify-content-center fw-bold">
            <div class="col-12 col-sm-3 my-2 m-sm-0">
              <input
                type="text"
                class="form-control boton-verde w-100"
                v-model="green_buttonEN"
              />
            </div>
            <div class="col-12 col-sm-3 my-2 m-sm-0">
              <input
                type="text"
                class="form-control boton-celeste w-100"
                v-model="blue_buttonEN"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="w-100 d-flex justify-content-center">
        <input
          type="text"
          class="form-control form-control-sm mb-5 subtitle"
          v-model="subtitle_2EN"
        />
      </div>
    </div>
    <div class="container">
      <div class="row m-0 section-table">
        <div
          class="col-12 col-lg-6 p-0"
          v-for="column in tableEN"
          :key="column.id"
        >
          <h3
            id="tablaEstrategiaTitulo"
            class="text-center wet-form pb-1 m-0"
            :class="{
              'container-titulo-estrategia': column.color === 'green',
              'container-titulo-ejecucion': column.color === 'blue',
            }"
          >
            <input class="form-control" v-model="column.title" />
          </h3>
          <div class="row container-informacion-tabla w-100 m-0">
            <div
              class="col-3 form wet-form"
              :class="{
                'th-verde': column.color === 'green',
                'th-celeste': column.color === 'blue',
              }"
              v-for="cellTitle in column.th"
              :key="cellTitle.id"
            >
              <input
                class="mb-0 fw-bold form-control"
                v-model="cellTitle.text"
              />
            </div>
            <div
              class="col-3 form wet-form"
              :class="{
                'tr-verde': column.color === 'green',
                'tr-celeste': column.color === 'blue',
              }"
              v-for="cell in column.tr"
              :key="cell.id"
            >
              <textarea
                class="my-1 form-control"
                v-model="cell.text"
                rows="3"
              />
            </div>
          </div>
        </div>
      </div>
      <button
        class="btn btn-success btn-lg mt-3 mb-3"
        @click="syncContent('EN')"
      >
        Save
      </button>
    </div>
  </section>
</template>

<script>
// import {ConsultaSeccion8x8} from "@/helpers/contenido.js";
import { getSection, putSection } from "../../helpers/contenido";
import store from "../../store";

export default {
  name: "Modelo8x8",
  data() {
    return {
      titleES: "Modelo Wetforest 8x8",
      subtitle_1ES:
        "Wetforest ha desarrollado un exitoso modelo 8X8. Framework que cubre 8 pilares.",
      green_buttonES: "4 en el ámbito estratégico",
      blue_buttonES: "4 en el de ejecución",
      subtitle_2ES:
        "y sus respectivos 8 ejes que cubren los elementos fundamentales de gestión de una empresa.",
      tableES: [],
      titleEN: "Modelo Wetforest 8x8",
      subtitle_1EN:
        "Wetforest ha desarrollado un exitoso modelo 8X8. Framework que cubre 8 pilares.",
      green_buttonEN: "4 en el ámbito estratégico",
      blue_buttonEN: "4 en el de ejecución",
      subtitle_2EN:
        "y sus respectivos 8 ejes que cubren los elementos fundamentales de gestión de una empresa.",
      tableEN: [],
    };
  },
  methods: {
    fetchData() {
      if (store.state.sections.wetforest8x8) {
        this.data = { ...store.state.sections.wetforest8x8.components };
      }
    },
    async fetchContent() {
      const section = await getSection("wetforest8x8");
      const sectionES = section.filter((section) => section.language == "ES");
      const sectionEN = section.filter((section) => section.language == "EN");

      this.sectionES = sectionES;
      this.sectionEN = sectionEN;

      this.titleES = sectionES[0].components.title;
      this.subtitle_1ES = sectionES[0].components.subtitle_1;
      this.green_buttonES = sectionES[0].components.green_button;
      this.blue_buttonES = sectionES[0].components.blue_button;
      this.subtitle_2ES = sectionES[0].components.subtitle_2;
      this.tableES = sectionES[0].components.table;

      this.titleEN = sectionEN[0].components.title;
      this.subtitle_1EN = sectionEN[0].components.subtitle_1;
      this.green_buttonEN = sectionEN[0].components.green_button;
      this.blue_buttonEN = sectionEN[0].components.blue_button;
      this.subtitle_2EN = sectionEN[0].components.subtitle_2;
      this.tableEN = sectionEN[0].components.table;
    },
    async syncContent(lan) {
      if (lan === "ES") {
        const words = {
          id: this.sectionES[0]._id,
          section: "wetforest8x8",
          components: {
            title: this.titleES,
            subtitle_1: this.subtitle_1ES,
            green_button: this.green_buttonES,
            blue_button: this.blue_buttonES,
            subtitle_2: this.subtitle_2ES,
            table: this.tableES,
          },
          language: "ES",
        };
        await putSection(words);
      } else {
        const words = {
          id: this.sectionEN[0]._id,
          section: "wetforest8x8",
          components: {
            title: this.titleEN,
            subtitle_1: this.subtitle_1EN,
            green_button: this.green_buttonEN,
            blue_button: this.blue_buttonEN,
            subtitle_2: this.subtitle_2EN,
            table: this.tableEN,
          },
          language: "EN",
        };
        await putSection(words);
      }
    },
  },
  watch: {
    "$store.state.sections": function () {
      this.fetchData();
    },
  },
  mounted() {
    this.fetchData();
    this.fetchContent();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
/* // Small devices (landscape phones, 576px and up)
 */

.__modelo8x8-container {
  h3 {
    font-family: InterMedium !important;
    font-size: 1.25rem !important;
  }
}

.section-table {
  p {
    font-size: 12px !important;
  }
}

@media (min-width: 782px) {
  .section-table {
    p {
      font-size: 16px !important;
    }

    .tr-verde > p {
      font-size: 12.5px !important;
      font-weight: bold;
    }

    .tr-celeste > p {
      font-size: 12.5px !important;
      font-weight: bold;
    }
  }
}

@media (min-width: 1200px) {
  .subtitle {
    width: 60% !important;
  }
}

.boton-verde {
  background-color: #e5fcec;
  color: #14993c;
  justify-content: center;
  border-radius: 10px;
  padding: 0;
}

.boton-celeste {
  background-color: #e0f7ff;
  color: #189bcd;
  padding: 0;
  justify-content: center;
  border-radius: 10px;
}

.th-celeste {
  background-color: #7fc8e3ff;

  margin-top: 8px;
  font-size: 18px;
  font-family: Interbold;
  padding: 5px;
  min-height: 66px;
  border-right: 3px solid #fff;
  border-bottom: 8px solid #fff;
  /*Centrado de texto*/
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.th-verde {
  background-color: #73d18dff;
  margin-top: 8px;
  font-size: 18px;
  padding: 5px;
  min-height: 66px;
  border-right: 3px solid #fff;
  border-bottom: 8px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.container-titulo-estrategia {
  background-color: #27ba54;
  color: #fff;
  border-radius: 5px;
  padding: 0.5rem;
  margin-right: 0.25rem;
}

.container-titulo-ejecucion {
  background-color: #40b9e6;
  color: #fff;
  padding: 0.5rem;
  border-radius: 5px;
  margin-left: 0.25rem;
}

hr.pill {
  height: 0px;
  margin: auto auto;
  border-radius: 2px;
  color: #14993c !important;
  border: 2px solid currentColor;
  width: 80%;
}

.tr-verde {
  min-height: 66px;
  background-color: #abdeaeff;
  border-right: 3px solid #fff;
  border-bottom: 3px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  & p {
    font-size: 10px !important;
    font-weight: bold;
  }
}

.tr-celeste {
  background-color: #b6e4f5;

  min-height: 66px;
  border-right: 3px solid #fff;
  border-bottom: 3px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  & p {
    font-size: 10px !important;
    font-weight: bold;
  }
}

.ms-8 {
  margin-left: 8rem;
}
</style>
